import request from '@/utils/request'

export default {
  // 获取学历接口
  getEducation(){
    return request({
      url: '/openreport/getEducation',
      method: 'get'
    })
  },
  // 获取提纲
  getOutline(education){
    return request({
      url: '/openreport/getOutline/' + education,
      method: 'get'
    })
  },
  // 获取字数
  getWordsNum(education){
    return request({
      url: '/openreport/getWordsNum/' + education,
      method: 'get'
    })
  }
}

<template>
  <div class="form-box">

    <FormTitle :infoName="infoName" />

    <!-- 标准版 -->
    <div class="form-list" v-show="ruleForm.extParam.modelCode === 1">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入开题报告标题" class="form-item-pc-show">
          <el-input v-model="ruleForm.title" style="position: relative; z-index: 11;"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    @focus="openTitleInfo"
                    maxlength="50" >
            <div class="btn-i-icon"
                 v-if="ruleForm.title.length > 0"
                 slot="suffix"
                 @click="handleIconClick">
              <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9406"
                   width="20" height="20"><path fill="#2f6bf1" d="M693.952 630.592l193.088 193.152-60.352 60.288L633.6 690.944l60.352-60.352zM614.4 72.512c12.8-4.224 29.888 4.288 29.888 17.088l34.112 226.112c4.288 4.288 4.288 8.576 4.288 12.8L819.2 486.4c8.512 8.512 8.512 25.6-8.512 29.888l-187.776 102.4c-4.224 4.224-8.512 4.224-8.512 8.512L490.688 832c-8.576 12.8-25.6 17.088-34.176 4.288l-153.6-174.976c-4.224-4.224-8.512-8.512-12.8-8.512L89.6 627.2c-12.8-4.288-21.312-17.088-17.088-29.888l89.6-196.224v-8.576l-25.6-226.112c-4.224-17.088 8.576-25.6 21.376-25.6l230.4 8.512H396.8z m-42.688 110.976l-145.024 51.2c-12.8 4.224-29.888 4.224-42.688 4.224L226.112 230.4 243.2 379.712c0 17.088 0 34.176-8.512 51.2l-51.2 123.776 119.424 12.8c25.6 0 51.2 12.8 68.288 34.112l93.888 110.912 81.024-128c8.576-17.024 21.376-29.824 38.4-38.4l119.488-64L618.688 384c-12.8-17.088-21.376-34.112-21.376-55.488l-25.6-145.024z" p-id="9407"></path>
              </svg>
              <span>优化标题</span>
            </div>
          </el-input>
          <TitleInfoBox ref="TitleInfo"
                        :title="ruleForm.title"
                        :code="ruleForm.creationDocCode"
                        @acceptTitle="acceptTitle"/>
        </el-form-item>
        <el-form-item label="请输入开题报告标题" class="form-item-h5-show">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入完整的标题，标题越完整，创作内容越准确"
                    maxlength="50">
          </el-input>
        </el-form-item>
        <el-form-item label="请选择学历">

          <div class="form-xl radio-pc-show">
            <div class="form-li" v-for="(item,index) in openReportInfo.education" :class="item.active === 1 ? 'active':''"
              @click="clickNav(index)">
              <div class="form-check" v-if="item.active === 1"><i class="el-icon-check"></i></div>
              <h3>{{item.label}}</h3>
              <p>{{item.tip}}</p>
            </div>
          </div>

          <el-radio-group v-model="ruleForm.extParam.education" class="radio-h5-show h5-el-radio" @input="clickNavH5">
            <el-radio v-for="item in openReportInfo.education" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item label="请选择报告字数">

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-pc-show">
            <el-radio-button v-for="item in openReportInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="ruleForm.extParam.creationWordsNum" class="radio-h5-show h5-el-radio">
            <el-radio v-for="item in openReportInfo.creationWordsNum" :label="item">{{item + '字左右'}}</el-radio>
          </el-radio-group>

        </el-form-item>
      </el-form>
    </div>

    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import OpenreportApi from '@/api/openreport'
  import { orderMixin } from '@/utils/orderMixin'
  import { validateTitle } from '@/utils/validate'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  import TitleInfoBox from "@/components/titleInfoBox/index.vue"
  export default {
    components: {TitleInfoBox, NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
    data() {
      return {
        ruleForm: {
          title: '',
          creationDocId: 5,
          creationDocCode: 'OPEN_REPORT',
          channelCode: null,
          extParam: {
            version: 1,
            modelCode: 1,
            education: null,
            creationWordsNum: ''
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入开题报告标题', trigger: 'blur' },
        //     { validator: validateTitle, trigger: 'blur' }
        //   ],
        //   extParam: {
        //     education: [
        //       { required: true, message: '请选择学历', trigger: 'change' }
        //     ],
        //     creationWordsNum: [
        //       { required: true, message: '请选择字数', trigger: 'change' }
        //     ]
        //   }
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        openReportInfo: {
          education: '',
          creationWordsNum: ''
        },
        paramsData: '', // 储存跳页面的传值
        textList: [{
          title: '智能搜索',
          content: '助你迅速找到心仪提纲'
        },{
          title: '海量提纲',
          content: '轻松探索各个领域的研究'
        },{
          title: '定制范文',
          content: '量身定制独一无二范文'
        }],
        showContent: true,
        questInfo: {
          education: '', // 学历
          level: '二级', // 提纲级别*
          sign: '', // 签名*
          subject: '', // 科目*
          timestamp: '', // 时间戳
          title: '', // 标题*
          wordsNum: '', // 字数
        },
        outline: [],
        outlineList: [],
        finalText: '',
        formLoading: false, //AI搜索加载
      }
    },
    created() {
      this.getEducation()
    },
    methods: {
      // 选择学历
      clickNav(index) {
        this.openReportInfo.education.forEach(item => {
          item.active = 0
        })
        this.openReportInfo.education[index].active = 1
        this.ruleForm.extParam.education = this.openReportInfo.education[index].value
        let num = this.openReportInfo.education[index].value
        this.getWordsNum(num)
      },
      // 选择学历 移动端
      clickNavH5(val){
        // console.log(val)
        this.getWordsNum(val)
      },
      // 获取学历接口
      getEducation() {
        OpenreportApi.getEducation().then(res => {
          let dataList = res.data
          dataList.forEach(item=>{
            item.active = 0
          })
          if(dataList.length >= 3){
            dataList[0].tip = '本科论文内容全面系统，融合理论与实践，涵盖文献综述、研究问题详述与研究方法阐释。'
            dataList[1].tip = '具备深度分析与综合能力，结合理论实践、案例分析与实验数据，提升研究深度与广度。'
            dataList[2].tip = '展现高水平科研成果，突出原创创新性，涵盖前沿理论、先进方法论、深度分析和独到见解。'
            dataList[3].tip = '致力于教育研究或科研项目，强调研究的重要性、创新性及其对学术界和教育实践的贡献。'
          }
          this.openReportInfo.education = dataList;
          this.clickNav(0)
          // let num = this.openReportInfo.education[0].value
          // this.getWordsNum(num)
        }).catch(() => {
        })
      },
      // 获取字数
      getWordsNum(num) {
        OpenreportApi.getWordsNum(num).then(res => {
          // console.log(res)
          this.openReportInfo.creationWordsNum = res.data;
          this.ruleForm.extParam.creationWordsNum = this.openReportInfo.creationWordsNum[0]
        }).catch(() => {
        })
      },
      submitForm(formName) {
        let _this = this
        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入开题报告标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }else if(_this.ruleForm.title.length < 5){
          return _this.$message({
            message: '标题长度需要至少5个字!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        // if(_this.$refs.outlineRef){
        //   let outlineData = _this.$refs.outlineRef.getOutlineData()
        //   _this.ruleForm.extParam.outline = JSON.stringify(outlineData)
        // }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)
        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
      openTitleInfo(){
        this.$refs.TitleInfo.openItme()
      },
      acceptTitle(item){
        this.ruleForm.title = item
      },
      handleIconClick(ev) {
        // console.log(ev);
        this.$refs.TitleInfo.optimize()
      }
    }
  }
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
.form-item-pc-show{
  display: block;
}
.form-item-h5-show{
  display: none;
}
.btn-i-icon{
  height: 30px;
  padding: 0 8px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #e7f3ff;
  color: #2f6bf1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  span{
    margin-left: 5px;
    font-weight: bold;
  }
}
.btn-i-icon:hover{
  background-color: #d8e3f8;
}

@media screen and (max-width: 900px) {
  .form-item-pc-show{
    display: none;
  }
  .form-item-h5-show{
    display: block;
  }
}
</style>
